import React, { Component } from "react";
import Button from '@mui/material/Button';
import successOrder from '../../../assets/payments/success.png';
import failOrder from '../../../assets/payments/fail.png';
import HpbLogo from '../../../assets/payments/HPBLogo.png';
import print from '../../../assets/payments/print.png';
import whatsapp from '../../../assets/payments/whatsapp.png';
import {QRCodeSVG} from 'qrcode.react';
import {
  Link,
} from "react-router-dom";
import commonConfigs from "../../../config";
import axios from "axios";
import ErrorAlert from "../../common/components/alertErrorComponent";

class SuccessfulPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hpbqr: "",
      timeout: false,
      seconds: process.env.REACT_APP_TIMEOUT,
      errorMsg: "",
      functionIndex:""
    }
    this.timer = 0;
    this.interval_start = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let secondstime = this.state.seconds - 1000;
    
    // Check if we're at zero.
    if (secondstime == 0) { 
      clearInterval(this.timer);
      this.setState({
        timeout: true,
      });
    } else {
       this.setState({
      seconds: secondstime,
    });
    }
  }
  async getHpbQr() {
    try {
    var hpbitemsarr = JSON.parse(localStorage.getItem('hpbitems'));

    //console.log(hpbitemsarr);
    var ordertotalamt = 0;
    if(hpbitemsarr !== null){
      for(var i = 0;i<hpbitemsarr.length;i++){
        ordertotalamt += parseFloat(hpbitemsarr[i].order_amt);
      }
      var today = new Date();
      var day = today.getDate();
      var mnth = today.getMonth();
      var year = today.getFullYear();

      var hr = today.getHours();
      var min = today.getMinutes();

      var monthNames = [
        "January", "February", "March",
        "April", "May", "June",
        "July", "August", "September",
        "October", "November", "December"
      ];
      
      var date = day + " " + monthNames[mnth] + " " + year + " " + hr + ":" + min + " UTC";

      var body = {
        consumer_id: "-",
        consumer_order_id: localStorage.getItem('orderid'),
        platform_type: 2, 
        location_code: localStorage.getItem('locationcode'),
        merchant_id: localStorage.getItem('merchantid'),
        order_datetime: localStorage.getItem('orderdatetime'),
        order_total_amt: ordertotalamt,
        order_type: parseInt(localStorage.getItem('dinemode')), 
        payment_type: 1,
        products: hpbitemsarr,
        payment_id: localStorage.getItem('txnRetrievalRef'),
      }
      console.log("successful payment body",body);
      
      const method = "POST";
      const sendBrickMortarUrl = commonConfigs.apiUrls.SendBrickMortar();
      const requestBody = body; 

      // Generate authentication headers
      const authHeaders = commonConfigs.generateHeaders(method, sendBrickMortarUrl, requestBody);

      await axios.post(sendBrickMortarUrl, body, { headers: authHeaders })
        .then((res) => {
          var response = res.result.data;
            console.log("HCS",response.hcs);    
            var text = JSON.stringify(response);
            var qrstring = "";
            const obj = JSON.parse(text, function (key, value) {
              if (key == "qr-string") {
                qrstring = value;
              }
            });
            if (qrstring !== "" && response.hcs == true) {
              console.log("set hpbqr")
              this.setState({
                hpbqr:qrstring
              })  
            }
        })
        .catch((err) => {
          this.setState({
            hpbqr:""
          })
        });
       
      }
    }catch(e){
      this.setState({
        errorMsg: "System Error Code",
        functionIndex: "02"
      }); 
    }
    
  }
  componentDidMount(){
    this.getHpbQr();
  }
  render() {
    clearInterval();
    this.startTimer();

    let dinemode = localStorage.getItem('dinemode');
    let stat = localStorage.getItem('status');

    let buttonDisplay;

    let textdisplay1;
    let textdisplay2;
    let textdisplay3;

    let hpbdisplay;

    let logodisplay;
    let contentstyle;

    if (stat == 'success') {
      if (window.innerWidth < 481) {
        logodisplay = <div className = "successOrder">
        <img src={successOrder} height="auto" width="20%" />
      </div>;
        textdisplay1 = "TRANSACTION COMPLETED";
        textdisplay2 = "You will be alerted once your food is available for collection via the mobile number provided";
        textdisplay3 =  <h2 className="order"  style={{fontSize:'18px'}}>Order No: #{localStorage.getItem('orderno')}</h2>;
        if(this.state.hpbqr){
          hpbdisplay = <><div className="HpbLogo">
          <img src={HpbLogo} style={{width: '30%'}} height="100%" /> 
          </div>
          <span className="HpbQrCode" >
            
            <QRCodeSVG value={this.state.hpbqr} size={150}/>
          </span>
          <p className="HpbSlogan" style={{fontSize:'18px'}}>Eat, Drink, Shop Healthy Challenge</p>
          <p className="text" style={{fontSize:'18px', marginTop:'10px', fontWeight:'300'}}>Scan this QR code with the Healthy 365 app by today to earn rewards and keep up your healthy habit!<br></br> Terms & conditions apply</p>
          </>;
        }else{
          hpbdisplay = <></>;
        }
        
        buttonDisplay = "DONE";
        contentstyle = { height: '80%', width:'95%', margin:'auto',marginTop:'10px'};
      } else {
        logodisplay = <div className = "successOrder">
        <img src={successOrder} height="auto" width="20%" />
      </div>;
        textdisplay1 = "TRANSACTION COMPLETED";
        textdisplay2 = "You will be alerted once your food is available for collection via the mobile number provided";
        textdisplay3 =  <h2 className="order"  style={{fontSize:'24px'}}>Order No: #{localStorage.getItem('orderno')}</h2>;
        if(this.state.hpbqr){
          hpbdisplay = <><div className="HpbLogo">
          <img src={HpbLogo} style={{width: '30%'}} height="100%" /> 
          </div>
          <span className="HpbQrCode" >
            
            <QRCodeSVG value={this.state.hpbqr} size={300}/>
          </span>
          <p className="HpbSlogan" style={{fontSize:'32px'}}>Eat, Drink, Shop Healthy Challenge</p>
          <p className="text" style={{fontSize:'32px', marginTop:'10px', fontWeight:'300'}}>Scan this QR code with the Healthy 365 app by today to earn rewards and keep up your healthy habit!<br></br> Terms & conditions apply</p>
          </>;
        }else{
          hpbdisplay = <></>;
        }
        
        buttonDisplay = "DONE";
        contentstyle = { height: '80%', width:'70%', margin:'auto',marginTop:'30px'};
      }
    

      
    } else {
      if (window.innerWidth < 481) {
        logodisplay = <div className = "successOrder">
        <img src={failOrder} height="auto" width="40%" />
      </div>;
        textdisplay1 = "TRANSACTION FAILED";
        textdisplay2 = "Transaction unsuccessful, please try again.";
        textdisplay3 =  <></>;
        buttonDisplay = "DONE";
        contentstyle = { height: '350px', width:'95%', margin:'auto', marginTop:'10px'};
      } else {
        logodisplay = <div className = "successOrder">
        <img src={failOrder} height="auto" width="10%" />
      </div>;
        textdisplay1 = "TRANSACTION FAILED";
        textdisplay2 = "Transaction unsuccessful, please try again.";
        textdisplay3 =  <></>;
        buttonDisplay = "DONE";
        contentstyle = { height: '350px', width:'70%', margin:'auto', marginTop:'10px'};
      }
    }
    
    if (window.innerWidth < 481) {
      return (
        <>
        {/* <script src="https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js"></script>
        <script type="text/javascript" src="$WEBAPIS/webapis/webapis.js"></script>
        <script type="text/javascript" src="$B2BAPIS/b2bapis/b2bapis.js"></script> */}
        <div >
  
          {/* <Bg/> */}
          <div className="content" style={contentstyle}>
          <div className="successPayment" >
          {logodisplay}
          <h2 className="text" style={{fontSize:'18px'}}>{textdisplay1}</h2>
          <p className="text" style={{fontSize:'12px', marginTop:'10px', fontWeight:'300'}}>{textdisplay2}</p>
          <span>
            {textdisplay3}
  
            {hpbdisplay}
          </span>
          <span className="center">
            <Link to='/' style={{textDecoration:'none'}}>
              <Button variant="contained" color="success" sx={{width: 150}} style={{backgroundColor:"#5BBA44", borderRadius:'10px', fontSize:'12px', height:'30px', marginTop:'20px'}}>{buttonDisplay}</Button>
            </Link>
          </span>
          </div>
          </div>
            <Link to={`/options/${localStorage.getItem('hawkername')}`}>
            <button id="mainpage" style={{display:'none'}}></button>
          </Link>
          </div>
          {this.state.errorMsg == "System Error Code" ? <ErrorAlert
          errorMsgDisplay={{
            title: this.state.errorMsg,
            body: "Successful Payment " + this.state.functionIndex
          }}
        ></ErrorAlert> : <></>}
        </>
        
      );
    } else {
      return (
        <>
        {/* <script src="https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js"></script>
        <script type="text/javascript" src="$WEBAPIS/webapis/webapis.js"></script>
        <script type="text/javascript" src="$B2BAPIS/b2bapis/b2bapis.js"></script> */}
        <div style={{margin:'10px'}}>
  
          <div className="content" style={contentstyle}>
          <div className="successPayment" >
          {logodisplay}
          <h2 className="text" style={{fontSize:'24px'}}>{textdisplay1}</h2>
          <p className="text" style={{fontSize:'18px', marginTop:'10px', fontWeight:'300'}}>{textdisplay2}</p>
          <span>
            {textdisplay3}
  
            {hpbdisplay}
          </span>
          <span className="center">
            <Link to='/' style={{textDecoration:'none'}}>
              <Button variant="contained" color="success" sx={{width: 300}} style={{backgroundColor:"#5BBA44", borderRadius:'10px', fontSize:'18px', height:'50px', marginTop:'50px'}}>{buttonDisplay}</Button>
            </Link>
          </span>
          </div>
          </div>
          <Link to='/'>
            <button id="mainpage" style={{display:'none'}}></button>
          </Link>
          </div>
          {this.state.errorMsg == "System Error Code" ? <ErrorAlert
          errorMsgDisplay={{
            title: this.state.errorMsg,
            body: "Successful Payment " + this.state.functionIndex
          }}
        ></ErrorAlert> : <></>}
        </>
        
      );
    }
  }
}
 
export default SuccessfulPayment;