import React, { Component } from "react";
import {
  Route,
  Routes,
  NavLink,
  HashRouter,
  BrowserRouter
} from "react-router-dom";
import "tw-elements";
////////////////////////////////////////////////WEB VERSION//////////////////////////////////////////////
import Navbar from "./web/common/components/navbarComponent"
import NavbarWrapper from "./web/open_api_module/components/navbarWrapper";

import CheckoutPage from "./web/checkout_module/views/checkoutPage"                         //CheckoutPage
import NotificationPagePage from "./web/checkout_module/views/notificationPage";            //NotificationPage
import PaymentPage from "./web/payments_module/views/paymentPage";                          //PaymentPage
import SuccessfulPaymentPage from "./web/payments_module/views/successPaymentPage"      //SuccessfulPaymentPage

import StallsPage from "./web/merchant_module/views/allMerchantPage"                           //StallsPage                    
import LandingPage from "./web/home_module/views/platformLandingPage"                                  //LandingPage
import LandingHawkerPage from "./web/home_module/views/locationsPage"                       //LandingHawkerPage                
import PrivacyPolicyPage from "./web/home_module/views/privacyPolicyPage"                  //PrivacyPolicyPage
import AboutPage from "./web/home_module/views/aboutPage"                                   //AboutPage                
import YNCPage from "./web/home_module/views/youthCommunityPage"                           //YNCPage                
import DiningModePage from "./web/home_module/views/userLandingPage"                            //DiningModePage
import PastOrdersPage from "./web/home_module/views/pastOrdersPage"                        //PastOrdersPage              
import OrderPage from "./web/product_module/views/productPage"                              //OrderPage         
import EditOrderPage from "./web/order_module/views/orderOptionsPage"                      //EditOrderPage                      

// API Documentation
import OpenApiPage from "./web/open_api_module/pages/mainLayout"
// import Documentation from "./web/open_api_module/pages/documentLayout"
import Intro from "./web/open_api_module/components/views/introductionApiLayout";
import UpdateOrderStatus from "./web/open_api_module/components/views/order_fulfillment_pages/updateOrderStatus";
import GetConsumerOrders from "./web/open_api_module/components/views/order_fulfillment_pages/getConsumerOrders";
import GetOrderById from "./web/open_api_module/components/views/order_fulfillment_pages/getOrderbyId";
import GetTopFiveHealthy from "./web/open_api_module/components/views/food_nutrition_pages/getTopFiveHealthy";
import GetTopFiveUnhealthy from "./web/open_api_module/components/views/food_nutrition_pages/getTopUnhealthy";
import GetLineGraphs from "./web/open_api_module/components/views/food_nutrition_pages/getLineGraphs";
import GetPieChart from "./web/open_api_module/components/views/food_nutrition_pages/getPieChart";
import GetCaloriesTransactions from "./web/open_api_module/components/views/food_nutrition_pages/getCaloriesTransaction";

import history from './history';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
class Main extends Component {
  
  render() {
    document.title = "SG Hawker Centres";
      return (
        <BrowserRouter>
          <div>
          <Navbar />
            <Routes history={history}>
              {/* Open API routing */} 
              <Route
            path="/documentation"
            element={
                <NavbarWrapper />
            }
              >
              <Route path="/documentation/introduction" element={<Intro/>}/>
              <Route path="/documentation/order/update-order-status" element={<UpdateOrderStatus/>}/>
              <Route path="/documentation/order/get-consumer-orders" element={<GetConsumerOrders/>}/>
              <Route path="/documentation/order/get-orders-by-id" element={<GetOrderById/>}/>

              <Route path="/documentation/nutrition/get-top-5-healthy" element={<GetTopFiveHealthy/>}/>
              <Route path="/documentation/nutrition/get-top-5-unhealthy" element={<GetTopFiveUnhealthy/>}/>
              <Route path="/documentation/nutrition/get-by-line-graphs" element={<GetLineGraphs/>}/>
              <Route path="/documentation/nutrition/get-by-pie-chart" element={<GetPieChart/>}/>
              <Route path="/documentation/nutrition/get-calories-transactions" element={<GetCaloriesTransactions/>}/>

          </Route>
              {/* <Route restricted={false} path="/documentation" element={<OpenApiPage/>}> */}
                {/* <Route index element={<Documentation />} /> */}
             
          {/* </Route> */}
               {/* <Route restricted={false} path="/documentation"element={<NavbarWrapper /> }>
                <Route index element={<Documentation />} />
                <Route path="/documentation/introduction" element={<Intro/>}/>
                
                <Route path="/documentation/order/update-order-status" element={<UpdateOrderStatus/>}/>
                <Route path="/documentation/order/get-consumer-orders" element={<GetConsumerOrders/>}/>
                <Route path="/documentation/order/get-orders-by-id" element={<GetOrderById/>}/>

                <Route path="/documentation/nutrition/get-top-5-healthy" element={<GetTopFiveHealthy/>}/>
                <Route path="/documentation/nutrition/get-top-5-unhealthy" element={<GetTopFiveUnhealthy/>}/>
                <Route path="/documentation/nutrition/get-by-line-graphs" element={<GetLineGraphs/>}/>
                <Route path="/documentation/nutrition/get-by-pie-chart" element={<GetPieChart/>}/>
                <Route path="/documentation/nutrition/get-calories-transactions" element={<GetCaloriesTransactions/>}/>

                <Route path="/documentation/cleaning/request-dishware" element={<RequestDishware/>}/>
                <Route path="/documentation/cleaning/get-dishware-requests" element={<GetDishwareRequest/>}/>
                <Route path="/documentation/cleaning/update-dishware-status" element={<UpdateDishwareStatus/>}/>
              </Route> */}
            <Route exact path="/options/:hawkername" element={<DiningModePage authed={true} />}></Route>
            <Route exact path="/" element={<LandingPage authed={true} />}></Route>
            <Route exact path="/locations" element={<LandingHawkerPage authed={true} />}></Route>            
            <Route exact path="/policy/privacy" element={<PrivacyPolicyPage authed={true} />}></Route>
            <Route exact path="/about" element={<AboutPage authed={true} />}></Route>
            <Route exact path="/community/youth" element={<YNCPage authed={true}/>}></Route>
            <Route exact path="/orders/history" element={<PastOrdersPage authed={true}/>}></Route>
            <Route exact path="/merchants" element={<StallsPage authed={true} />}></Route>
            <Route exact path="/products" element={<OrderPage authed={true}/>}></Route>
            <Route exact path="/orders" element={<EditOrderPage authed={true}/>}></Route>
            <Route exact path="/checkout" element={<CheckoutPage authed={true}/>}></Route>
            <Route exact path="/checkout/contact" element={<NotificationPagePage authed={true}/>}></Route>
            <Route exact path="/payments" element={<PaymentPage authed={true}/>}></Route>
            <Route exact path="/success" element={<SuccessfulPaymentPage authed={true} />}></Route>
              
          </Routes>
        </div>
      </BrowserRouter>
      );
    
  }
}
 
export default Main;