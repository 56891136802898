import styles from '../../../styles/styles.module.css';

const GetTopFiveHealthy = () => {
    if (window.innerWidth < 481) {
        return (
            <div style={{maxWidth: '100%'}}> 
            
            {/* Details component */}
            <div style={{gridArea: 'details', marginBottom: '0px'}}>
                <div className={styles.cardbod}>
                    <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#72b84c'}}>Get Top 5 Healthy</h3>
                    <hr />
                    <p style={{marginTop: '10px'}}> This API retrieves the top 5 healthy items. </p>
    
                    {/* API Parameters */}
                    <div style={{marginTop: '20px'}}>
                        <h6 style={{textDecoration: 'underline'}}>Parameters</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a.SSO ID</p>
                            <p>b. date filter option : 1-d, 1-m, 1-y</p>
                        </div>
    
                        <h6 style={{textDecoration: 'underline', marginTop: '10px'}}>Output data</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a. order id</p>
                            <p>b. consumer id</p>
                            <p>c. product id</p>
                            <p>d. product name</p>
                            <p>e. calorie colour code</p>
                            <p>f. date time</p>
                            <p>e. total calorie count</p>
                            <p>g. merchant name</p>
                            <p>h. location name</p>
                            <p>i. updated on</p>
                            <p>j. add ons</p>
                        </div>
                    </div>
                </div>
            </div>
    
            {/* Code component */}
            <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
                <div style={{backgroundColor: '#72b84c', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '5px' , paddingBottom: '5px'}}>
                    Get Top 5 Healthy API
                </div>
    
                <div>
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Request (GET)</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                            <code className={styles.code}>/api/v1/common/health/individual-consumption-trends/list-views/top-healthier-items?sso_id=&date_filter=</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'10px', color: 'white'}}>Example</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                                <code className={styles.code}>/api/v1/common/health/individual-consumption-trends/list-views/top-healthier-items?sso_id={ '{sso_id}'}&date_filter=1-m</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'10px', color: 'white'}}>Header</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2}>"authorization":</span> <span className={styles.codestyle3}>"Bearer example"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            {/* <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>dy1UG9zWBfLRVNTQtWQr</span> */}
                                    <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>{'{apikey}'}</span>
                            <br />
                            <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                            <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            
                            <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2}>"data"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br />
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"{"}</span>
                            <br/>
    
                            {/* start of data */}
                            <div style={{marginLeft: '15px'}}>
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"order_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 499<span className={styles.codestyle1}>,</span></span>
                                <br />
                                        <span className={styles.codestyle2} style={{ marginLeft: '10px' }}>"consumer_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> {'{consumer_id}'}<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"product_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> {'{product_id}'}<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"product_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "[Cold] Soya Bean Drink"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"calorie_color_syscode"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "#008450"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"calorie_count"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 30<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"date_time"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "2023-04-19 12:21:29"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"total_calorie_count"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 30<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Stall 53"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"location_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Bukit Canberra Hawker Center"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"updated_on"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "2022-12-16T17:40:45.000Z"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"add_ons"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"["}</span><span className={styles.codestyle1}>{"]"}</span>
                                <br />
                            </div>
                            {/* ending brackets */}
                            <span className={styles.codestyle1} style={{marginLeft: '26px'}}>{"}"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
                            <span className={styles.codestyle1} style={{marginLeft: '20px'}}>{"]"}</span>
                            <br/>
                            <span className={styles.codestyle1} style={{marginLeft: '15px'}}>{"}"}</span>
                            <br/>
                            <span className={styles.codestyle1}>{"}"}</span>
                            </code>
                        </blockquote>   
                    </div>
    
                </div>
            </div>
            </div>
        );
    } else {
        return (
            <div style={{display: 'grid', 'gridTemplateAreas':`'details details code`,'gridTemplateColumns': 'repeat(3, minmax(0, 1fr))', gap:3, maxWidth: 'inherit'}}> 
            
            {/* Details component */}
            <div style={{gridArea: 'details', marginBottom: '0px'}}>
                <div className={styles.cardbod}>
                    <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#72b84c'}}>Get Top 5 Healthy</h3>
                    <hr />
                    <p style={{marginTop: '10px'}}> This API retrieves the top 5 healthy items. </p>
    
                    {/* API Parameters */}
                    <div style={{marginTop: '20px'}}>
                        <h6 style={{textDecoration: 'underline'}}>Parameters</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a.SSO ID</p>
                            <p>b. date filter option : 1-d, 1-m, 1-y</p>
                        </div>
    
                        <h6 style={{textDecoration: 'underline', marginTop: '10px'}}>Output data</h6>
                        <div style={{paddingLeft: '10px'}}>
                            <p>a. order id</p>
                            <p>b. consumer id</p>
                            <p>c. product id</p>
                            <p>d. product name</p>
                            <p>e. calorie colour code</p>
                            <p>f. date time</p>
                            <p>e. total calorie count</p>
                            <p>g. merchant name</p>
                            <p>h. location name</p>
                            <p>i. updated on</p>
                            <p>j. add ons</p>
                        </div>
                    </div>
                </div>
            </div>
    
            {/* Code component */}
            <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
                <div style={{backgroundColor: '#72b84c', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '5px' , paddingBottom: '5px'}}>
                    Get Top 5 Healthy API
                </div>
    
                <div>
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Request (GET)</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                            <code className={styles.code}>/api/v1/common/health/individual-consumption-trends/list-views/top-healthier-items?sso_id=&date_filter=</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'10px', color: 'white'}}>Example</h6>
                        <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                                <code className={styles.code}>/api/v1/common/health/individual-consumption-trends/list-views/top-healthier-items?sso_id={ '{sso_id}'}&date_filter=1-m</code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'10px', color: 'white'}}>Header</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2}>"authorization":</span> <span className={styles.codestyle3}>"Bearer example"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            {/* <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>dy1UG9zWBfLRVNTQtWQr</span> */}
                                    <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>{'{apikey}'}</span>
                            <br />
                            <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                        </blockquote>
                    </div>
    
                    <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                        <h6 style={{marginTop:'15px', color: 'white'}}>Sample Response</h6>
                        <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                            <code>
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            
                            <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                            <br />
                            <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            <span className={styles.codestyle2}>"data"</span><span className={styles.codestyle1}>:</span>
                            <span className={styles.codestyle1}>{"["}</span>
                            <br />
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"{"}</span>
                            <br/>
    
                            {/* start of data */}
                            <div style={{marginLeft: '15px'}}>
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"order_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 499<span className={styles.codestyle1}>,</span></span>
                                <br />
                                        <span className={styles.codestyle2} style={{ marginLeft: '10px' }}>"consumer_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> {'{consumer_id}'}<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"product_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> {'{product_id}'}<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"product_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "[Cold] Soya Bean Drink"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"calorie_color_syscode"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "#008450"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"calorie_count"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 30<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"date_time"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "2023-04-19 12:21:29"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"total_calorie_count"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 30<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Stall 53"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"location_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Bukit Canberra Hawker Center"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"updated_on"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "2022-12-16T17:40:45.000Z"<span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"add_ons"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"["}</span><span className={styles.codestyle1}>{"]"}</span>
                                <br />
                            </div>
                            {/* ending brackets */}
                            <span className={styles.codestyle1} style={{marginLeft: '26px'}}>{"}"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
                            <span className={styles.codestyle1} style={{marginLeft: '20px'}}>{"]"}</span>
                            <br/>
                            <span className={styles.codestyle1} style={{marginLeft: '15px'}}>{"}"}</span>
                            <br/>
                            <span className={styles.codestyle1}>{"}"}</span>
                            </code>
                        </blockquote>   
                    </div>
    
                </div>
            </div>
            </div>
        );
    }
  };
  
  export default GetTopFiveHealthy;
