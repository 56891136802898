import { sha256 } from 'js-sha256';
import { Buffer } from 'buffer';

function signRequest(
  host,
  method,      // GET, PUT, POST, DELETE
  projectId,
  platformSyscode,
  url,         // path+query
  body = {},        // request body (undefined of none)
  credential,  // access key id
  secret)      // access key value (base64 encoded)
{
console.log('running sign request')
console.log(method)
var verb = method.toUpperCase();
var isoNow = new Date().toISOString();

//Generate content-sha256 hash
var contentHash = Buffer.from(sha256.create().update(JSON.stringify(body)).array()).toString("base64");

// SignedHeaders
var signedHeaders = "datetime|host|project-id|platform-syscode|content-sha256";

// String-To-Sign
var stringToSign = `${verb}\n${url}\n${isoNow}|${host}|${projectId}|${platformSyscode}|${contentHash}`;

// Signature
var signature = Buffer.from(sha256.create().update(`${stringToSign}${secret}`).array()).toString("base64");

console.log('datetime', isoNow);
console.log('content-sha256', contentHash);
console.log('authoriz', "HMAC-SHA256 Credential=" + credential + "&SignedHeaders=" + signedHeaders + "&Signature=" + signature);

// Result request headers
return [
{ name: "datetime", value: isoNow },
{ name: "content-sha256", value: contentHash },
{ name: "Authorization", value: "HMAC-SHA256 Credential=" + credential + "&SignedHeaders=" + signedHeaders + "&Signature=" + signature }
];
}

let commonUrl = `${process.env.REACT_APP_BEVEAT_URL}`;
let pefUrl = `${process.env.REACT_APP_PAYEATFWD_URL}`;
let orgid = `${process.env.REACT_APP_ORG_ID}`;

const commonConfigs = {
  accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
  redirectUrl: "http://localhost:3000/portal/signin",
  apiHeader: {
    'api-key': `${process.env.REACT_APP_API_KEY}`,
    'project-id': `${process.env.REACT_APP_PROJECT_ID}`,
    'platform-syscode': `${process.env.REACT_APP_ECOMMERCE_WEBSITE_PLATFORM_SYSCODE}`
  },
  // Generate headers for API request 
  // This function generates datetime, content-sha256, and authorization key on top of the common API header
  generateHeaders: function (method, url, body = {}) {
    const parsedUrl = new URL(url);
    const host = parsedUrl.host;
    const secret = process.env.REACT_APP_SECRET_KEY; // Project Secret Key
    const requestUrlPathQuery = `${parsedUrl.pathname}${parsedUrl.search}`;

    const authHeaders = signRequest(
      host,
      method,
      this.apiHeader['project-id'],
      this.apiHeader['platform-syscode'],
      requestUrlPathQuery,
      body,
      this.apiHeader['api-key'],
      secret
    );

    const headers = {
      ...this.apiHeader, // Add common api headers
      ...authHeaders.reduce((acc, header) => {
        acc[header.name] = header.value;
        return acc;
      }, {})
    };
  
    return headers;
  },
  apiUrls: {
    //Request QR
    // RequestQr: (projectname) => `${commonUrl}/v1/hub/${projectname}/payments/nets-qr/request`,
    // QueryQr: (projectname) => `${commonUrl}/v1/hub/${projectname}/payments/nets-qr/query`,
    // ReversalQr: (projectname) => `${commonUrl}/v1/hub/${projectname}/payments/nets-qr/reversal`,
    // WebhookQr: (retrieval_ref) => 
    //   // `${commonUrl}/v1/common/payments/nets-qr/webhook?retrieval_ref=${retrieval_ref}`
    //   `${commonUrl}/v1/common/payments/nets/webhook?retrieval_ref=${retrieval_ref}`,
    requestNetsApi: () => `${commonUrl}/v1/common/payments/nets-qr/request`,
    queryNetsApi: () => `${commonUrl}/v1/common/payments/nets-qr/query`,
    webhookNetsApi: (txnRetrieval_ref) => `${commonUrl}/v1/common/payments/nets/webhook?txn_retrieval_ref=${txnRetrieval_ref}`,
    ReversalQr: () => `${commonUrl}/v1/common/payments/nets-qr/reversal`,
    //Get Stalls
    // GetStalls: (projectname, orgid, loccode) => `${commonUrl}/v1/hub/${projectname}/org/${orgid}/locations/outlets/merchants?location_code=${loccode}&cluster=`,
    // GetStalls: (orgid, loccode,merchanttype) => `${commonUrl}/v1/common/org/${orgid}/locations/outlets/merchants?location_code=${loccode}&cluster=&merchant_type=${merchanttype}`,
    GetStalls: (orgid, loccode) => `${commonUrl}/v1/common/org/${orgid}/locations/outlets/merchants?location_code=${loccode}`,
    //Get HPB Categories
    GetHPBCategories: () => `${commonUrl}/v1/common/hpb/categories`,
    //Get Menu
    // GetMenuProducts: (orgid,merchantid,pef) => `${commonUrl}/v1/common/org/${orgid}/merchants/${merchantid}/products?platform_type_sys_code=${process.env.REACT_APP_ECOMMERCE_WEBSITE_PLATFORM_SYSCODE}&payeatfwd=${pef}`,
    GetMenuProducts: (orgid,merchantid) => `${commonUrl}/v1/common/org/${orgid}/merchants/${merchantid}/products?platform_syscode=${process.env.REACT_APP_ECOMMERCE_WEBSITE_PLATFORM_SYSCODE}`,
    //Checkout  
    CreateOrder: (projectname) =>
    `${commonUrl}/v1/hub/${projectname}/transactions/orders`,
    // View Past Orders
    // GetOutlets: (orgid, outlettype) =>
    //   `${commonUrl}/v1/common/org/${orgid}/locations/outlets?outlet_type=${outlettype}`,
    GetOutlets: () =>
      `${commonUrl}/v1/common/org/${orgid}/locations/outlets/gps?latitude=1.4489743&longitude=103.8206482`,
    GetTxnHistory: (mobile, page, projectname) =>
      `${commonUrl}/v1/hub/${projectname}/transactions/consumers/${mobile}/orders?page=${page}`,
    SendBrickMortar: () =>
      `${commonUrl}/v1/common/hpb/rdcs/brick-mortar`,
    GetVouchers: (projectname, orgid, vouchercode,orderval, accountid) =>
      `${commonUrl}/v1/hub/${projectname}/vouchers?org_id=${orgid}&platform_type_sys_code=${process.env.REACT_APP_ECOMMERCE_WEBSITE_PLATFORM_SYSCODE}&voucher_code=${vouchercode}&lat=&lng=&consumer_id=${accountid}&order_val=${orderval}`,
  },
};
// const configs = Object.assign(commonConfigs, envConfigs);
export default commonConfigs;
