import React, { Component } from "react";
import Button from '@mui/material/Button';
import netsInfo from '../../../assets/payments/netsInfo.png'
import txnLoading from '../../../assets/payments/loading.gif'
import sad from '../../../assets/payments/sad.png'
import refresh from '../../../assets/payments/refresh.png'
import {
  Link,
} from "react-router-dom";
import commonConfigs from "../../../config";
import axios from "axios";
import ErrorAlert from "../../common/components/alertErrorComponent";
import { EventSourcePolyfill } from 'event-source-polyfill';

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      convertTime: {},
      secondsNetsTimeout: 303,
      amount: localStorage.getItem("amt"),
      txnId: localStorage.getItem("txn_id"),
      mobile: parseInt(localStorage.getItem("mobile")),
      netsQrPayment: txnLoading,
      netsQrRetrievalRef: "",
      networkCode: "",
      instructions: "",
      netsQrGenerate: false,
      errorMsg: "",
      functionIndex: "",
      merchantId: localStorage.getItem("merchantid"),
      orderId: localStorage.getItem("orderid"),
      projectName: localStorage.getItem("projectname"),
    };
    this.netsTimer = 0;

    this.queryNets = this.queryNets.bind(this);
    this.startNetsTimer = this.startNetsTimer.bind(this);
    this.decrementNetsTimer = this.decrementNetsTimer.bind(this);
  }
  async requestNets(amt, txnId, mobile) {
    try {
      var body = {
        // consumer_order_id: orderId,
        // amount: amt,
        // merchant_id: this.state.merchantId,
        // project_name: this.state.projectName,
        //add mobile for backend test 
        //notify_mobile: parseInt(localStorage.getItem('mobile'))
        txn_id: txnId,
        amt_in_dollars: amt,
        notify_mobile: mobile,
      }
      console.log(body);

      const method = "POST";
      const requestNetsApiUrl = commonConfigs.apiUrls.requestNetsApi();
      const requestBody = body; 

      // Generate authentication headers
      const authHeaders = commonConfigs.generateHeaders(method, requestNetsApiUrl, requestBody);

      await axios.post(requestNetsApiUrl, body, { headers: authHeaders })
        .then((res) => {
          var response = res.data.result.data;
          console.log("response", response);

          if (response.qr_code !== "") {
            localStorage.setItem('txnRetrievalRef', response.txn_retrieval_ref)
            this.startNetsTimer();
            this.setState({
              netsQrPayment: "data:altImage/png;base64," + response.qr_code,
              netsQrRetrievalRef: response.retrieval_ref
            }
            )
            this.webhookNets();
          } else {
            this.setState({
              netsQrPayment: "",
              instructions: response.instruction,
              networkCode: response.network_status
            })
          }
        })
        .catch((err) => {
          console.log("Error Message: ", err);
          this.setState({
            netsQrGenerate: true
          })
        });
    } catch (e) {
      this.setState({
        errorMsg: "System Error Code",
        functionIndex: "01"
      })
    }
  }

  async queryNets() {
    try {
      var netsTimeoutStatus = 0;
      if (this.state.secondsNetsTimeout === 0) {
        netsTimeoutStatus = 1;
      }

      if (this.state.netsQrRetrievalRef && this.state.netsQrGenerate !== true) {
        var body = {
          retrieval_ref: this.state.netsQrRetrievalRef,
          frontend_timeout_status: netsTimeoutStatus,
          // project_name: this.state.projectName,
        }
        console.log(body);

        const method = "POST";
        const queryNetsApiUrl = commonConfigs.apiUrls.queryNetsApi();
        const requestBody = body; 
  
        // Generate authentication headers
        const authHeaders = commonConfigs.generateHeaders(method, queryNetsApiUrl, requestBody);

        await axios.post(queryNetsApiUrl, body, { headers: authHeaders })
          .then((res) => {
            var response = res.data.result.data;
            console.log(response);

            if (response.txn_status === 0) {
            } else if (response.txn_status === 1) {
              localStorage.setItem('status', 'success');
              this.setState({
                netsQrRetrievalRef: ""
              });
              document.getElementById('successPage').click();
            } else {
              localStorage.setItem('status', 'fail');
              document.getElementById('successPage').click();
            }
          })
          .catch((err) => {
            console.log("Error Message: ", err);
            localStorage.setItem('status', 'fail');
            document.getElementById('successPage').click();
          });
      }
    } catch (e) {
      this.setState({
        errorMsg: "System Error Code",
        functionIndex: "02"
      })
    }
  }
  webhookNets() {
    if (this.s2sNetsTxnStatus) {
      this.s2sNetsTxnStatus.close();
    }

    const method = "GET";
    const webhookUrl = commonConfigs.apiUrls.webhookNetsApi(localStorage.getItem('txnRetrievalRef'));
    const requestBody = {}; 

    // Generate authentication headers
    const authHeaders = commonConfigs.generateHeaders(method, webhookUrl, requestBody);

    // const url = commonConfigs.apiUrls.webhookNetsApi(localStorage.getItem('retrievalRef'));
    this.s2sNetsTxnStatus = new EventSourcePolyfill(webhookUrl, {
      headers: authHeaders,
      heartbeatTimeout: 150000
    });

    this.s2sNetsTxnStatus.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      console.log(data.message);
      console.log("Message detected");
      // If the message indicates QR code scanned successfully, call queryNets
      if (data.message === 'QR code scanned') {
        if (this.s2sNetsTxnStatus) {
          this.s2sNetsTxnStatus.close();
        }
        localStorage.setItem('status', 'success');
        this.setState({
          netsQrRetrievalRef: ""
        });
        document.getElementById('successPage').click();
      } else if (data.message === 'Timeout occured') {
        if (this.s2sNetsTxnStatus) {
          this.s2sNetsTxnStatus.close();
        }
        this.queryNets();
      }
    })
  }
  startNetsTimer() {
    if (this.netsTimer === 0 && this.state.secondsNetsTimeout > 0) {
      this.netsTimer = setInterval(this.decrementNetsTimer, 1000);
    }
  }
  convertTimeFormat(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let secondsNetsTimeout = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": secondsNetsTimeout
    };
    return obj;
  }
  decrementNetsTimer() {
    // Remove one second, set state so a re-render happens.
    let secondsNetsTimeout = this.state.secondsNetsTimeout - 1;
    this.setState({
      convertTime: this.convertTimeFormat(secondsNetsTimeout),
      secondsNetsTimeout: secondsNetsTimeout,
    });

    // Check if we're at zero.
    if (secondsNetsTimeout === 0) {
      clearInterval(this.netsTimer);
    }
  }

  componentDidMount() {
    this.requestNets(this.state.amount, this.state.txnId, this.state.mobile);
  }
  handleCancel() {
    if (this.s2sNetsTxnStatus) {
      this.s2sNetsTxnStatus.close();
    }
    this.setState({
      netsQrRetrievalRef: ""
    }, () => document.getElementById('cancelBtn').click())
  }
  handleRefresh() {
    this.requestNets(this.state.amount, this.state.txnId, this.state.mobile); // refresh qr code on screen
    this.setState({
      netsQrGenerate: false, // set state to false to remove refresh button
      convertTime: {},
      secondsNetsTimeout: 303
    })
    this.startNetsTimer();
  }
  render() {
    if (window.innerWidth < 481) {
      return (
        <div style={{ paddingTop: '10px' }}>
          <div className="content" style={{ padding: '10px', margin: 'auto', width: '95%' }}>
            {this.state.networkCode !== 0 ? <div className="successPayment" style={{ padding: '10px' }}>
              <h2 className="text" style={{ fontSize: '18px', marginBottom: '10px' }}>SCAN TO PAY</h2>
              <p className="text" style={{ fontSize: '12px', fontWeight: '300' }}>Scan with your  your DBS, OCBC, UOB app to complete your payment</p>
              {this.state.netsQrPayment !== ""}

              {
                this.state.netsQrGenerate == true ?
                  <span>
                    <div id="nets" style={{ position: 'relative' }} className="netsQrCode">
                      <img id="imgNetsQr" style={{ opacity: '0.1' }} height="auto" width="60%" src={this.state.netsQrPayment} />
                      <h4 id="netsQrCodeError" style={{ position: 'absolute', top: '20%' }}>QR Code Error</h4>
                      <Button variant="contained" sx={{ width: 180 }} style={{ position: 'absolute', top: '60%', bottom: '50%', backgroundColor: "#E02020", borderRadius: '10px', fontSize: '12px', textDecoration: 'none', height: "35px" }} onClick={() => this.handleRefresh()}><img src={refresh} style={{ paddingRight: '10px' }} />Refresh QR</Button>
                    </div>
                    <h2 className="netsTimer" style={{ fontSize: '18px' }}> {this.state.convertTime.m} : {this.state.convertTime.s}</h2>
                  </span>
                  :
                  <span>
                    <div id="nets" className="netsQrCode">
                      <img alt="altImage" id="imgNetsQr" height="auto" width="50%" src={this.state.netsQrPayment} />
                    </div>
                    <h2 className="netsTimer" style={{ fontSize: '12px' }}> {this.state.convertTime.m} : {this.state.convertTime.s}</h2>
                  </span>
              }
              <img alt="altImage" id="netsInfo" height="auto" width="70%" src={netsInfo} />
              <div className="button" style={{ marginTop: '20px' }}>
                <Button variant="contained" sx={{ width: 100 }} style={{ backgroundColor: "#E02020", borderRadius: '10px', fontSize: '12px', textDecoration: 'none', height: "30px" }} onClick={() => this.handleCancel()}>Cancel</Button>
              </div>
            </div> : <div className="successPayment" style={{ padding: '20px' }}>
              <h2 className="text" style={{ fontSize: '18px', marginBottom: '10px' }}>{this.state.instructions}</h2>
              <span>
                <img alt="altImage" height="auto" width="60%" src={sad} />
              </span>
              <div className="button" style={{ marginTop: '20px' }}>
                <Button variant="contained" sx={{ width: 100 }} style={{ backgroundColor: "#E02020", borderRadius: '10px', fontSize: '12px', textDecoration: 'none', height: "30px" }} onClick={() => this.handleCancel()}>Retry</Button>
              </div>
            </div>}
            <Link to='/success'>
              <button id="successPage" style={{ visibility: 'hidden' }} >To Success Page</button>
            </Link>
            <Link to='/checkout'>
              <button id="cancelBtn" style={{ visibility: 'hidden' }} >To Cart Page</button>
            </Link>

          </div>
          {this.state.errorMsg != "" ? <ErrorAlert errorMsgDisplay={{
            title: this.state.errorMsg,
            body: "Payment " + this.state.functionIndex,
          }} /> : <></>}
        </div>
      );
    } else {
      return (
        <div style={{ margin: '20px' }}>
          <div className="content" style={{ padding: '10%', paddingBottom: '3%', paddingTop: '5%', margin: 'auto', width: '60%' }}>
            {this.state.networkCode !== 0 ? <div className="successPayment" style={{ padding: '10px' }}>
              <h2 className="text" style={{ fontSize: '24px', marginBottom: '10px' }}>SCAN TO PAY</h2>
              <p className="text" style={{ fontSize: '20px', fontWeight: '300' }}>Scan with your  your DBS, OCBC, UOB, NetsPay app to complete your payment</p>
              {this.state.netsQrPayment !== ""}
              {
                this.state.netsQrGenerate == true ?
                  <span>
                    <div id="nets" style={{ position: 'relative' }} className="netsQrCode">
                      <img id="imgNetsQr" style={{ opacity: '0.1' }} height="auto" width="60%" src={this.state.netsQrPayment} />
                      <h1 id="netsQrCodeError" style={{ position: 'absolute', top: '20%' }}>QR Code Error</h1>
                      <Button variant="contained" sx={{ width: 200 }} style={{ position: 'absolute', top: '60%', bottom: '50%', backgroundColor: "#E02020", borderRadius: '10px', fontSize: '14px', textDecoration: 'none', height: "40px" }} onClick={() => this.handleRefresh()}><img src={refresh} style={{ paddingRight: '10px' }} />Refresh QR</Button>
                    </div>
                    <h2 className="netsTimer" style={{ fontSize: '18px' }}> {this.state.convertTime.m} : {this.state.convertTime.s}</h2>
                  </span>
                  :
                  <span>
                    <div id="nets" className="netsQrCode">
                      <img alt="altImage" id="imgNetsQr" height="auto" width="50%" src={this.state.netsQrPayment} />
                    </div>
                    <h2 className="netsTimer" style={{ fontSize: '12px' }}> {this.state.convertTime.m} : {this.state.convertTime.s}</h2>
                  </span>
              }
              <img alt="altImage" id="netsInfo" height="auto" width="50%" src={netsInfo} />
              <div className="button" style={{ marginTop: '20px' }}>
                <Button variant="contained" sx={{ width: 200 }} style={{ backgroundColor: "#E02020", borderRadius: '10px', fontSize: '18px', textDecoration: 'none', height: "50px" }} onClick={() => this.handleCancel()}>Cancel</Button>
              </div>
            </div> : <div className="successPayment" style={{ padding: '10px' }}>
              <h2 className="text" style={{ fontSize: '24px', marginBottom: '10px' }}>{this.state.instructions}</h2>
              <span>
                <img alt="altImage" height="auto" width="60%" src={sad} />
              </span>
              <div className="button" style={{ marginTop: '20px' }}>
                <Button variant="contained" sx={{ width: 200 }} style={{ backgroundColor: "#E02020", borderRadius: '10px', fontSize: '18px', textDecoration: 'none', height: "50px" }} onClick={() => this.handleCancel()}>Retry</Button>
              </div>
            </div>}
            <Link to='/success'>
              <button id="successPage" style={{ visibility: 'hidden' }} >To Success Page</button>
            </Link>
            <Link to='/checkout'>
              <button id="cancelBtn" style={{ visibility: 'hidden' }} >To Cart Page</button>
            </Link>

          </div>
          {this.state.errorMsg != "" ? <ErrorAlert errorMsgDisplay={{
            title: this.state.errorMsg,
            body: "Payment " + this.state.functionIndex,
          }} /> : <></>}
        </div>
      );
    }
  }
}

export default Payment;